import hello from 'hellojs';
import {
    signInDefinition,
    helloJsOptions,
    signIn,
} from './b2cConfig';

export default {
    init () {
        this.initSignIn();

        hello.init(
            {
                signIn: process.env.VUE_APP_B2C_APPID,
            },
            helloJsOptions,
        );
    },
    signIn () {
        hello(signIn).login();
    },
    signOut () {
        hello.logout(signIn, { force: true });
    },
    initSignIn () {
        hello.init({
            signIn: signInDefinition,
        });
    },
};
