
import { Vue, Component, Prop } from 'vue-property-decorator';
import { updateUI } from '@/observables/UI';
import TermsWording from '@/components/TermsWording.vue';
import router from '@/router';
import Auth from '@/services/authentication';

@Component({
    components: {
        TermsWording,
    },
})
export default class Terms extends Vue {
  @Prop({ default: false, type: Boolean, required: true }) readonly showAccept: boolean;

  public check: boolean = false;

  /**
   * User accepts T&C's
   */
  accept (): void {
      updateUI.updateSeenTerms();
      localStorage.setItem('LCPMonitorTerms', new Date().getTime().toString());
  }

  /**
   * T&C's declined, so return to login
   */
  returnToLogin (): void {
      Auth.init();
      Auth.signOut();
  }

  /**
   * Hide T&C's (must already be accepted)
   */
  close (): void {
      router.go(-1);
  }
}
