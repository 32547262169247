import axios from 'axios';
import SessionTimer from '@/common/SessionTimer';
import router from './router';
import { updateUI } from './observables/UI';

const timer: SessionTimer = new SessionTimer(120, 2);

axios.interceptors.response.use((response: any) => {
    timer.updateLastTouch();
    updateUI.updateError(false);
    if (response.status === 200 || response.status === 204) {
        return response;
    }
    return null;
}, (error: any) => {
    updateUI.updateError(true);
    let status: number = 503;
    let text: string = 'Service unavailable. Please contact your administrator.';
    if (error.response) {
        status = error.response.status;
        text = error.response.data;
    }
    if (status === 401) {
        updateUI.updateSeenTerms();
        updateUI.setUnauth();
        updateUI.setLoader(false);
        router.push({ name: 'nosetup' });
    } else {
        router.push({ name: 'error', params: { status: status.toString(), text } });
    }

    return Promise.reject(error); // #20175 https://stackoverflow.com/questions/49967779/axios-handling-errors
});
