
import { Vue, Component, Prop } from 'vue-property-decorator';

import Multiselect from 'vue-multiselect';
import moment from 'moment';
import axios from 'axios';
import { RingLoader } from '@saeris/vue-spinners';
import AppLogo from '@/components/AppLogo.vue';
import { updateUI, ui } from '@/observables/UI';
import { data, updateData } from '@/observables/Data';
import { client } from '@/observables/Client';
import ModalComponent from '@/components/ModalComponent.vue';
import AppNavBar from '@/components/AppNavBar.vue';

@Component({
    components: {
        Multiselect,
        RingLoader,
        ModalComponent,
        AppLogo,
        AppNavBar,
    },
})
export default class TopBar extends Vue {
    @Prop({ type: Boolean, required: true }) readonly isPendingSite: boolean;

    @Prop({ type: Boolean, required: true }) readonly isLoading: boolean;

    @Prop({ type: Boolean, required: true }) readonly isError: boolean;

    get currency (): string {
        return data.currency.currencyCode;
    }

    get portfolioViewType () {
        return client.selectedTypePortfolio.type;
    }

    get clientInfo () {
        return client.info;
    }

    get clientId (): string {
        return client && client.info && client.info.clientId;
    }

    get portfolioType () {
        return client && client.selectedTypePortfolio && client.selectedTypePortfolio.type;
    }

    get CurrencyOptions (): string[] {
        if (client.details) {
            const options = client.details.currencies.map((x) => x.currencyCode);
            return options;
        }
        return [];
    }

    get isClientsPage (): boolean {
        return this.activePage === 'clients';
    }

    get isDemo (): boolean {
        return client.details.demo;
    }

    get seenTerms (): boolean {
        return ui.seenTerms;
    }

    get activePage () {
        return ui.activePage;
    }

    get showDateOptions (): boolean {
        if (this.activePage === 'lookthrough' || this.activePage === 'esg' || this.activePage === 'esgManager') { return false; }
        return true;
    }

    get dateRangeLong () {
        return `${moment(data.startDate).format('DD MMM YYYY')} to ${moment(data.endDate).format('DD MMM YYYY')}`;
    }

    get dateRangeShort () {
        return `${moment(data.startDate).format('DD/MM/YY')} to ${moment(data.endDate).format('DD/MM/YY')}`;
    }

    get loggedIn () {
        return this.$store.state.auth.authObject !== null;
    }

    get clientName () {
        return client.info ? client.info.clientName : '';
    }

    get IsNull (): boolean {
        if (data.levelData === null) return true;
        return false;
    }

    get IsUnAuth (): boolean {
        return ui.unauth;
    }

    toggleDateRangePicker (): void {
        updateUI.SetDatePicker(!ui.datePicker);
    }

    updateCurrency (payload: string): void {
        updateUI.setLoader(true);
        updateData.updateCurrency(payload);
        updateData.getDataUpdate().then(() => {
            updateUI.setLoader(false);
        });
    }

    isPending = false;

    showModal: boolean = false;

    responseStatus: number = -1;

    get wasPTLRequestSuccessful (): boolean {
        return this.responseStatus === 200;
    }

    pushToLiveModal () {
        this.showModal = true;
    }

    closePTLModal () {
        this.showModal = false;
        this.auditText = '';
        this.responseStatus = -1;
    }

    auditText: string = '';

    async pushToLive (): Promise<void> {
        if (!(this.$refs.audit as HTMLTextAreaElement).reportValidity()) return;

        this.isPending = true;
        const requestObj = {
            clientId: client.info.clientId,
            clientName: client.info.clientName,
            portfolioName: data.base.portfolioData.name,
            userComment: this.auditText,
        };
        const response = await axios.post(`${process.env.VUE_APP_API}/api/ClonePortfolio`, requestObj);
        if (!response) {
            this.closePTLModal();
        } else {
            this.responseStatus = response.status;
            this.isPending = false;
        }
    }
}
