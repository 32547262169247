import ClimateMetrics from './ClimateMetrics';
import GovernanceMetrics from './GovernanceMetrics';

export default class MamagerESGData {
    ManagerId: string;

    Overall: number;

    OverallIndex: number;

    Environmental: number;

    EnvironmentalMetrics: ClimateMetrics;

    EnvironmentalIndex: number;

    EnvironmentalIndexMetrics: ClimateMetrics;

    Social: number;

    SocialIndex: number;

    Governance: number;

    GovernanceMetrics: GovernanceMetrics;

    GovernanceIndex: number;

    GovernanceIndexMetrics: GovernanceMetrics;

    // constructor (managerId: string,
    //     overall: number,
    //     environmental: number,
    //     social: number,
    //     governanace: number) {
    //     this.ManagerId = managerId;
    //     this.Overall = overall;
    //     this.Environmental = environmental;
    //     this.Social = social;
    //     this.Governance = governanace;
    // }
    constructor (managerId: string) {
        this.ManagerId = managerId;
        const overallScore = Math.round((Math.random() * 5 + 4) * 10) / 10;
        const overallScoreIndex = Math.round((Math.random() * 5 + 4) * 10) / 10;
        this.Overall = overallScore;
        this.OverallIndex = overallScoreIndex;

        this.Environmental = Math.max(Math.min(Math.round((overallScore - 1 + (Math.random() - 0.5) * 4) * 10) / 10, 9.5), 2);
        this.EnvironmentalMetrics = new ClimateMetrics(this.Environmental);
        this.EnvironmentalIndex = Math.max(Math.min(Math.round((overallScoreIndex - 1 + (Math.random() - 0.5) * 4) * 10) / 10, 9.5), 2);
        this.EnvironmentalIndexMetrics = new ClimateMetrics(this.EnvironmentalIndex);

        this.Social = Math.max(Math.min(Math.round((overallScore - 1 + (Math.random() - 0.5) * 3) * 10) / 10, 9.5), 2);
        this.SocialIndex = Math.max(Math.min(Math.round((overallScoreIndex - 1 + (Math.random() - 0.5) * 3) * 10) / 10, 9.5), 2);

        this.Governance = Math.max(Math.min(Math.round((overallScore - 1 + (Math.random() - 0.5) * 3) * 10) / 10, 9.5), 2);
        this.GovernanceMetrics = new GovernanceMetrics(this.Governance);
        this.GovernanceIndex = Math.max(Math.min(Math.round((overallScoreIndex - 1 + (Math.random() - 0.5) * 3) * 10) / 10, 9.5), 2);
        this.GovernanceIndexMetrics = new GovernanceMetrics(this.GovernanceIndex);
    }
}
