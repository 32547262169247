
import { Vue, Component } from 'vue-property-decorator';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { updateUI } from '@/observables/UI';
import TermsWording from '@/components/TermsWording.vue';
import { data } from '@/observables/Data';
import { client } from '@/observables/Client';
import AppLogo from '@/components/AppLogo.vue';

@Component({
    components: {
        TermsWording,
        AppLogo,
    },
})
export default class PrinterModal extends Vue {
    printing: boolean = false;

    get dateRange () {
        return `${moment(data.startDate).format('DD MMM YYYY')} to ${moment(data.endDate).format('DD MMM YYYY')}`;
    }

    get currency () {
        return data.currency.currencyCode;
    }

    get clientName () {
        return client.info ? client.info.clientName : '';
    }

    createPrint () {
        const printOptions = {
            margin: 0.3,
            filename: 'monitor.pdf',
            image: {
                type: 'jpeg',
                quality: 0.98,
            },
            html2canvas: {
                dpi: 192,
                scale: 2,
                letterRendering: true,
                useCORS: true,
                onrendered (canvas) {
                    const ctx = canvas.getContext('2d');
                    ctx.webkitImageSmoothingEnabled = true;
                    ctx.mozImageSmoothingEnabled = true;
                    ctx.imageSmoothingEnabled = true;
                },

            },
            jsPDF: {
                unit: 'in',
                format: 'letter',
                orientation: 'landscape',
            },
            pagebreak: {
                mode: 'avoid-all',
                before: '.terms-panel',
            },
        };

        const refDoc: HTMLDivElement = this.$refs.printDoc as HTMLDivElement;
        const htmlref: any = refDoc.innerHTML;

        html2pdf().from(htmlref).set(printOptions).save()
            .then(() => {
                updateUI.updatePrint(false);
            });
    }

    cancel () {
        updateUI.updatePrint(false);
    }
}
