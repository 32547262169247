import Vue from 'vue';
import { IClientDetailsDto } from '@/interfaces/dto/IClientDetails';
// eslint-disable-next-line import/no-cycle
import { updateData } from './Data';
// eslint-disable-next-line import/no-cycle
import { manageESGData } from './ManagerESG';

export default class Client {
    clientId: string;

    clientName: string;

    hasPendingPortfolioAccess: boolean;

    hasLivePortfolioAccess: boolean;

    constructor (
        clientId: string,
        clientName: string,
        hasPendingPortfolioAccess: boolean,
        hasLivePortfolioAccess: boolean,
    ) {
        this.clientId = clientId;

        this.clientName = clientName;

        this.hasPendingPortfolioAccess = hasPendingPortfolioAccess;

        this.hasLivePortfolioAccess = hasLivePortfolioAccess;
    }
}

interface Store {
    info: Client,
    clients: Client[],
    selectedTypePortfolio: {
        type: 'pending' | 'live',
    },
    details: IClientDetailsDto,
}

export const client: Store = Vue.observable({
    info: null, // contains basic information on client and user's access to live and pending setups
    clients: [],
    selectedTypePortfolio: {
        type: 'pending', // default to pending - this is also set to 'live' if directly enter dashboard with no view params
    },
    details: null, // contains more detailed information on the client depending on the selected type
});

export const updateClient = {
    setSelectedTypePortfolio (portfolioType: 'pending' | 'live') {
        client.selectedTypePortfolio.type = portfolioType;
    },
    updateClients (clients: Client[]) {
        client.clients = clients;
    },
    async updateClient (id: string, portfolioView: string) {
        if (id !== null) {
            const c = client.clients.find((cl) => cl.clientId === id);
            client.info = c;
            this.setSelectedTypePortfolio(portfolioView);
            await updateData.initialiseClient(c);
            manageESGData.initialise();
        } else {
            client.info = null;
        }
    },
    updateClientDetails (details: IClientDetailsDto) {
        client.details = details;
    },
};
