
import { Vue, Component, Prop } from 'vue-property-decorator';

import Multiselect from 'vue-multiselect';
import { RingLoader } from '@saeris/vue-spinners';
import AppLogo from '@/components/AppLogo.vue';
import Auth from '@/services/authentication';
import { updateUI, ui } from '@/observables/UI';
import ModalComponent from '@/components/ModalComponent.vue';
import { INavItem } from '@/interfaces/INavItem';
import Store from '@/store/store';

@Component({
    components: {
        Multiselect,
        RingLoader,
        ModalComponent,
        AppLogo,
    },
})
export default class NavBar extends Vue {
    @Prop({ type: String, required: false }) readonly portfolioViewType: 'pending' | 'live' | null;

    @Prop({ type: String, required: false }) readonly clientId: string | null;

    @Prop({ type: Boolean, required: true }) readonly isDemoClient: boolean;

    @Prop({ type: Boolean, required: true }) readonly isLoading: boolean;

    logOut () {
        Auth.init();
        Auth.signOut();
    }

    get isLcp (): boolean {
        return Store.getters['auth/isLcp'];
    }

    get navItems (): INavItem[] {
        const links = [
            {
                icon: 'chart-network',
                tooltip: 'Dashboard',
                route: `/dashboard/${this.clientId}/${this.portfolioViewType}`,
                isCurrentView: this.activePage === 'dashboardwithidandview' || this.activePage === 'dashboardwithid',
            },
            {
                icon: 'calendar-day',
                tooltip: 'Snapshot',
                route: '/snapshot',
                isCurrentView: this.activePage === 'snapshot',
            },
            {
                icon: 'balance-scale',
                tooltip: 'Historical Performance',
                route: '/managerperformance',
                isCurrentView: this.activePage === 'managerperformance',
            },
            {
                icon: 'chart-scatter',
                tooltip: 'Risk Return Analysis',
                route: '/riskreturn',
                isCurrentView: this.activePage === 'riskreturn',
            },
        ];
        if (this.isDemoClient) {
            links.push(
                {
                    icon: 'eye',
                    tooltip: 'Lookthrough Analysis',
                    route: '/lookthrough',
                    isCurrentView: this.activePage === 'lookthrough',
                },
                {
                    icon: 'chart-bar',
                    tooltip: 'Attribution Analysis',
                    route: '/attribution',
                    isCurrentView: this.activePage === 'attribution',
                },
                {
                    icon: 'coins',
                    tooltip: 'Risk Analysis',
                    route: '/risk',
                    isCurrentView: this.activePage === 'risk',
                },
            );
        }

        if (this.isLcp) {
            links.push(
                {
                    icon: 'tree',
                    tooltip: 'Responsible Investment Tool',
                    route: '/ritool',
                    isCurrentView: this.activePage === 'esg',
                },
            );
        }

        return links;
    }

    print () {
        updateUI.updatePrint(true);
    }

    get isClientsPage (): boolean {
        return this.activePage === 'clients';
    }

    get activePage () {
        return ui.activePage;
    }
}
