export default {
    namespaced: true,
    state: {
        columnsVisible: {
            marketValueStart: true,
            cashflows: true,
            marketValueEnd: true,
            assetAllocation: true,
            strategicAllocation: true,
            relativeAssetAllocation: true,
            relativeAssetAllocationRating: true,
            returnOverPeriod: true,
            benchmarkReturnOverPeriod: true,
            relativeReturnOverPeriod: true,
            lcpResearchView: true,
            lcpInvestmentScore: true,
        },
        clientId: null,
    },
    getters: {
        anyColumnHidden (state) {
            return Object.values(state.columnsVisible).some((visible) => !visible);
        },
    },
    mutations: {
        showAllColumns (state) {
            Object.keys(state.columnsVisible).forEach((key) => {
                state.columnsVisible[key] = true;
            });
        },
        hideColumn (state, { column }) {
            if (Object.hasOwnProperty.call(state.columnsVisible, column)) {
                state.columnsVisible[column] = false;
            }
        },
        saveCurrentClientId (state, { clientId }) {
            state.clientId = clientId;
        },
    },
    actions: {
        showAllColumns ({ commit }) {
            commit('showAllColumns');
        },
        hideColumn ({ commit }, { column }) {
            commit('hideColumn', { column });
        },
        saveCurrentClientId ({ commit }, { clientId }) {
            commit('saveCurrentClientId', { clientId });
        },
    },

};
