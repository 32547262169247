import Vue from 'vue';
import Notifications from 'vue-notification';
import {
    faAnalytics,
    faAngleRight,
    faArrowAltLeft,
    faArrowAltRight,
    faArrowDown,
    faArrowToRight,
    faBalanceScale,
    faBolt,
    faCalendarAlt,
    faCalendarDay,
    faChartBar,
    faChartLine,
    faChartNetwork,
    faChartPie,
    faChartScatter,
    faCheck,
    faCloud,
    faCog,
    faCoins,
    faComments,
    faDrawCircle,
    faEquals,
    faEye,
    faFileCertificate,
    faFingerprint,
    faGlobe,
    faGlobeAmericas,
    faIndustryAlt,
    faInfoCircle,
    faLayerGroup,
    faLeaf,
    faLeafHeart,
    faLock,
    faMinus,
    faMoneyCheckEditAlt,
    faPlus,
    faPrint,
    faProjectDiagram,
    faSackDollar,
    faSignOutAlt,
    faSort,
    faSortAlt,
    faSortNumericDownAlt,
    faSortNumericUpAlt,
    faThumbsDown,
    faUser,
    faUserChart,
    faFolderTree,
    faLink,
    faSearch,
    faTree,
    faTimes,
    faChevronDoubleLeft,
    faEyeSlash,
    faSun,
    faComment,

} from '@fortawesome/pro-duotone-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import PrettyCheckbox from 'pretty-checkbox-vue';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VTooltip from 'v-tooltip';
import App from './App.vue';
import router from './router';
import store from './store/store';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'pretty-checkbox/src/pretty-checkbox.scss';

import './intercept';

Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

library.add(
    faChevronDoubleLeft,
    faTimes,
    faSearch,
    faLink,
    faFolderTree,
    faAnalytics,
    faAngleRight,
    faArrowAltLeft,
    faArrowAltRight,
    faArrowDown,
    faArrowToRight,
    faBalanceScale,
    faBolt,
    faCalendarAlt,
    faCalendarDay,
    faChartBar,
    faChartLine,
    faChartNetwork,
    faChartPie,
    faChartScatter,
    faCheck,
    faCloud,
    faCog,
    faCoins,
    faComments,
    faDrawCircle,
    faEquals,
    faEye,
    faFileCertificate,
    faFingerprint,
    faGlobe,
    faGlobeAmericas,
    faIndustryAlt,
    faInfoCircle,
    faLayerGroup,
    faLeaf,
    faLeafHeart,
    faTree,
    faLock,
    faMinus,
    faMoneyCheckEditAlt,
    faPlus,
    faPrint,
    faProjectDiagram,
    faSackDollar,
    faSignOutAlt,
    faSort,
    faSortAlt,
    faSortNumericDownAlt,
    faSortNumericUpAlt,
    faThumbsDown,
    faUser,
    faUserChart,
    faEyeSlash,
    faSun,
    faComment,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(require('vue-moment'));

Vue.use(VTooltip);
Vue.use(Notifications);
Vue.use(PrettyCheckbox);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
