
import { Vue, Component } from 'vue-property-decorator';
import { updateUI } from '@/observables/UI';
import Auth from '@/services/authentication';

@Component({
    components: {
    },
})
export default class TimerModal extends Vue {
    secondsRemaining: number = 60;

    handle: number;

    created () {
        const self = this;
        this.handle = setInterval(() => {
            self.reduceTimer();
        }, 1000);
    }

    reduceTimer () {
        this.secondsRemaining -= 1;

        if (this.secondsRemaining === 0) {
            Auth.init();
            Auth.signOut();
            clearInterval(this.handle);
        }
    }

    beforeDestroy () {
        clearInterval(this.handle);
    }

    refreshData () {
        updateUI.updateInactivity(false);
    }
}

