export default class ClimateMetrics {
    EmmisionsIntensity: number;

    Trend: number;

    FossilFuelReserves: number;

    constructor (environmentScore: number) {
        // eslint-disable-next-line no-mixed-operators
        const scoreEm = Math.round((Math.random() * 2 + 8) * 10000 / environmentScore) / 100;
        this.EmmisionsIntensity = scoreEm;

        // eslint-disable-next-line no-mixed-operators
        const scoreTrend = Math.round((Math.random() * 2 + 8) * -400 / environmentScore) / 100;
        this.Trend = scoreTrend;

        // eslint-disable-next-line no-mixed-operators
        const scoreReserves = Math.round((Math.random() * 2 + 8) * 100 / environmentScore) / 100;
        this.FossilFuelReserves = scoreReserves;
    }
}
