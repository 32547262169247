
import { Vue, Component, Watch } from 'vue-property-decorator';
import { ScaleLoader } from '@saeris/vue-spinners';
import * as Highcharts from 'highcharts';
import ClickOutside from 'vue-click-outside';
import { ui, updateUI } from '@/observables/UI';
import { chartSettings } from '@/objects';
import BottomBar from '@/components/layout/BottomBar.vue';
import DatePicker from '@/components/layout/DatePicker.vue';
import PrinterModal from '@/components/PrinterModal.vue';
import Terms from '@/views/terms.vue';
import TimerModal from '@/components/layout/TimerModal.vue';
import TopBar from '@/components/layout/TopBar.vue';
import { client } from '@/observables/Client';

@Component({
    components: {
        TopBar,
        ScaleLoader,
        BottomBar,
        TimerModal,
        DatePicker,
        Terms,
        PrinterModal,
    },
    directives: {
        ClickOutside,
    },
})
export default class App extends Vue {
    created () {
        Highcharts.setOptions(chartSettings);
        this.checkForAuthReset();
        this.checkForTerms();
    }

    mounted () {
        this.APP_STATE = process.env.VUE_APP_STATE;
    }

    APP_STATE: 'pending' | 'live' = 'pending';

    get isPendingSite (): boolean {
        return this.APP_STATE === 'pending';
    }

    get showTopBar (): boolean {
        return !this.isCurrentRouteLogin && !this.isCurrentRouteDataError && this.clientSelected;
    }

    get showBottomBar (): boolean {
        return !this.isCurrentRouteLogin;
    }

    get isCurrentRouteLogin (): boolean {
        return this.currentRouteName === 'clients' || this.currentRouteName === 'login' || this.currentRouteName === null; // null ~= loading
    }

    get isCurrentRouteDataError (): boolean {
        return this.currentRouteName === 'health-check-error' || this.currentRouteName === 'non-lcp-error';
    }

    get clientSelected () {
        return client.info !== null && client.details !== null;
    }

    get currentRouteName (): string {
        return this.$route.name;
    }

    get seenTerms (): boolean {
        return ui.seenTerms;
    }

    get termsOnly (): boolean {
        return ui.activePage === 'terms';
    }

    get showTimerModal (): boolean {
        return ui.inactivity && this.loggedIn;
    }

    get showDatePicker (): boolean {
        return ui.datePicker;
    }

    get loggedIn (): boolean {
        return this.$store.state.auth.authObject !== null;
    }

    get loader (): boolean {
        return ui.loader;
    }

    get error (): boolean {
        return ui.error;
    }

    get print (): boolean {
        return ui.print;
    }

    checkForAuthReset (): void {
        // eslint-disable-next-line radix
        const localStorageAuthReset = localStorage.getItem('LCPMonitorAuthReset');
        if (localStorageAuthReset !== null) {
            const localAuthResetTime = parseInt(localStorageAuthReset, 10) / 1000;
            if (localAuthResetTime < 1696935269) {
                localStorage.removeItem('hello');
                localStorage.setItem('LCPMonitorAuthReset', new Date().getTime().toString());
            }
        } else {
            localStorage.removeItem('hello');
            localStorage.setItem('LCPMonitorAuthReset', new Date().getTime().toString());
        }
    }

    checkForTerms (): void {
        // eslint-disable-next-line radix
        const localStorageTermsAgreed = localStorage.getItem('LCPMonitorTerms');
        if (localStorageTermsAgreed !== null) {
            const localStorageTermsAgreedTime = parseInt(localStorageTermsAgreed, 10) / 1000;
            if (localStorageTermsAgreedTime > ui.termslastUpdated) {
                updateUI.updateSeenTerms();
            } else {
                localStorage.removeItem('LCPMonitorTerms');
                localStorage.removeItem('hello');
            }
        }
    }

    @Watch('currentRouteName')
    hideDatePicker (): void {
        updateUI.SetDatePicker(false);
    }
}
