/* eslint-disable */
import { ui } from '@/observables/UI';
import UILabel from '@/assets/models/UILabel';
import ValueFormat from '@/assets/models/ValueFormat';
import { Util } from './common/Util';


const chartSettings: any = {
    chart: {
        backgroundColor: 'transparent',
        style: {
            color: '#fff',
        },
        spacingBottom: 0,
        spacingTop: 30,
        spacingLeft: 0,
        zoomType: 'xy',
        panning: true,
        panKey: 'shift',
        height: null,
        width: null,
    },
    credits: {
        style: {
            color: '#fff',
        },
        enabled: false,
    },
    legend: {
        itemStyle: {
            color: '#fff',
        },
        enabled: false,
    },
    plotOptions: {
        line: {
            animation: false,
            marker: {
                enabled: false,
            },
        },
        series: {
            animation: false,
            lineWidth: 3,
            marker: {
                symbol: 'circle'
            },
            stickyTracking: false
        }
    },
    xAxis: {
        crosshair: true,
        labels: {
            style: {
                color: '#fff',
            },
        },
        title: {
            style: {
                color: '#fff',
            },
            text: 'Development Year',
        },
        plotBands: {
            color: '#303030',
        },
        gridLineWidth: 1,
        gridLineColor: 'rgb(48, 48, 48)'
    },
    yAxis: {
        gridLineColor: '#303030',
        title: {
            style: {
                color: '#fff',
            },
            text: null,
        },
        labels: {
            format: null,
        },
    },
    series: [],
    title: {
        text: null,
    },
    tooltip: {
        // backgroundColor: 'White',
        // borderRadius: 0,
        // borderWidth: 4,
        // useHTML: true,
        // // formatter: chartToolTip,
        // followPointer: true,
        // snap: 0
    },
};

// function standardChartTip (point) {
//     let yValue: string = `${Util.valueFormat(point!.point.y, 1000000, 2, 'usd')}`;
//     let yLabel = '';
//     let seriesName = '';
//     if (point!.point.series.userOptions.label !== undefined) {
//         yLabel = point!.point.series.userOptions.label;
//         seriesName = point!.point.series.name;
//     } else {
//         yLabel = point!.series.userOptions.data[0].label;
//         seriesName = point!.key;
//     }

//     return `
//     <div class="tooltip-wrapper">
//         <div class="title" style="color: #000">${seriesName}</div>
//         <div>
//             <div class="flex-wrapper">
//                 <div class="label">${yLabel}:</div>
//                 <div> <b>${yValue}</b></div>
//             </div>
//             <div class="flex-wrapper">
//                 <div class="label">Development Year:</div>
//                 <div><b>${point!.point.x}</b></div>
//             </div>
//         </div>
//     </div>`;
// }

// function alternateChartTip (point) {
//     return `
//     <div class="tooltip-wrapper">
//         <div>
//             <div class="flex-wrapper">
//                 <div class="label">Range:</div>
//                 <div> <b>${point!.point.low.toFixed(0)}% - ${point!.point.high.toFixed(0)}%</b></div>
//             </div>
//         </div>
//     </div>`;
// }

// function chartToolTip () {
//     // return alternateChartTip(this);
//     if (Object.prototype.hasOwnProperty.call(this!.point, 'high')) {
//         return alternateChartTip(this);
//     } else {
//         return standardChartTip(this);
//     }

// }

const tooltipSettings = {
    // Default tooltip placement relative to target element
    defaultPlacement: 'auto',
    // Default CSS classes applied to the tooltip element
    defaultClass: 'vue-tooltip-theme',
    // Default CSS classes applied to the target element of the tooltip
    defaultTargetClass: 'has-tooltip',
    // Is the content HTML by default?
    defaultHtml: true,
    // Default HTML template of the tooltip element
    // It must include `tooltip-arrow` & `tooltip-inner` CSS classes (can be configured, see below)
    // Change if the classes conflict with other libraries (for example bootstrap)
    defaultTemplate:
    '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
    // Selector used to get the arrow element in the tooltip template
    defaultArrowSelector: '.tooltip-arrow, .tooltip__arrow',
    // Selector used to get the inner content element in the tooltip template
    defaultInnerSelector: '.tooltip-inner, .tooltip__inner',
    // Delay (ms)
    defaultDelay: 0,
    // Default events that trigger the tooltip
    defaultTrigger: 'hover focus',
    // Default position offset (px)
    defaultOffset: 0,
    // Default container where the tooltip will be appended
    defaultContainer: 'body',
    defaultBoundariesElement: undefined,
    defaultPopperOptions: {},
    // Class added when content is loading
    defaultLoadingClass: 'tooltip-loading',
    // Displayed when tooltip content is loading
    defaultLoadingContent: '...',
    // Hide on mouseover tooltip
    autoHide: true,
    // Close tooltip on click on tooltip target?
    defaultHideOnTargetClick: true,
    // Auto destroy tooltip DOM nodes (ms)
    disposeTimeout: 5000,
    // Options for popover
    popover: {
        defaultPlacement: 'bottom',
        // Use the `popoverClass` prop for theming
        defaultClass: 'vue-popover-theme',
        // Base class (change if conflicts with other libraries)
        defaultBaseClass: 'tooltip popover',
        // Wrapper class (contains arrow and inner)
        defaultWrapperClass: 'wrapper',
        // Inner content class
        defaultInnerClass: 'tooltip-inner popover-inner',
        // Arrow class
        defaultArrowClass: 'tooltip-arrow popover-arrow',
        // Class added when popover is open
        defaultOpenClass: 'open',
        defaultDelay: 0,
        defaultTrigger: 'click',
        defaultOffset: 0,
        defaultContainer: 'body',
        defaultBoundariesElement: undefined,
        defaultPopperOptions: {},
        // Hides if clicked outside of popover
        defaultAutoHide: true,
        // Update popper on content resize
        defaultHandleResize: true,
    },
};

export {
    chartSettings, tooltipSettings,
};
