import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line
import auth from './modules/auth';
import snapshot from './modules/snapshot';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        snapshot,
    },
});
