import Vue from 'vue';
import Router from 'vue-router';

import axios from 'axios';
import moment from 'moment';
import Auth from '@/services/authentication'; // eslint-disable-line no-use-before-define
import Client, { client, updateClient } from '@/observables/Client';
import Store from './store/store'; // eslint-disable-line no-use-before-define
import lsm from './services/localstoragemanager'; // eslint-disable-line no-use-before-define
import { updateUI } from './observables/UI';
import { data } from './observables/Data';

Vue.use(Router);

const userLoggedIn = async () => {
    // check to see if we have the auth details in STATE
    const isLoggedIn = true;
    if (!Store.getters['auth/getAuthState']) {
        const result = await Store.dispatch('auth/checkIfLoggedIn');
        return result;
    }
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => resolve(isLoggedIn));
};

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/clients',
            name: 'clients',
            component: () => import('./views/Clients.vue'),
            beforeEnter: (to, from, next) => {
                const storedRoute = lsm.getDirectRoute();

                if (storedRoute != null) {
                    next(storedRoute);
                } else if (client.clients.length === 1) {
                    next({ name: 'dashboard' });
                } else {
                    updateUI.updateActivePage(to.name);
                    next();
                }
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/dashboard/:clientId/',
            name: 'dashboardwithid',
            component: () => import('./views/Dashboard.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/dashboard/:clientId/:portfolioView/',
            name: 'dashboardwithidandview',
            component: () => import('./views/Dashboard.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/snapshot',
            name: 'snapshot',
            component: () => import('./views/Snapshot.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/managerperformance',
            name: 'managerperformance',
            component: () => import('./views/ManagerPerformanceView.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/riskreturn',
            name: 'riskreturn',
            component: () => import('./views/RiskReturn.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/esg',
            name: 'esg',
            component: () => import('./views/ESG.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/ritool',
            name: 'ritool',
            beforeEnter (_to, _from, _next) {
                // Put the full page URL including the protocol http(s) below
                const riURL = process.env.VUE_APP_RI_URL;
                const clientID = client.info?.clientId;
                const endDate = moment(data.endDate).format('YYYY-MM-DD');
                const riURLWithParams = `${riURL}${clientID}/portfolios/${client.selectedTypePortfolio?.type}/${endDate}/summary`;

                // Create a URL object
                const url = new URL(riURLWithParams);

                // Add currency as a search parameter
                const currency = client.details.defaultCurrency.currencyCode;
                url.searchParams.append('currency', currency);

                // Open the URL in a new window
                window.open(url.toString(), '_blank');
                return false;
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/climatedashboard/:managerId',
            name: 'climatedashboardManager',
            component: () => import('./views/ClimateDashboardManager.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/climatedashboard',
            name: 'climatedashboard',
            component: () => import('./views/ClimateDashboard.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/esgdetail',
            name: 'esgdetail',
            component: () => import('./views/ESGDetail.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/lookthrough',
            name: 'lookthrough',
            component: () => import('./views/Lookthrough.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/risk',
            name: 'risk',
            component: () => import('./views/Risk.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/attribution',
            name: 'attribution',
            component: () => import('./views/attribution.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/Login.vue'),
            beforeEnter: (to, from, next) => {
                next();
            },
        },
        {
            path: '/terms',
            name: 'terms',
            props: true,
            component: () => import('./views/terms.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
            meta: {
                requiresAuth: false,
                requiresClientData: false,
            },
        },
        {
            path: '/',
            name: 'root',
            redirect: '/clients',
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/error',
            name: 'error',
            component: () => import('./views/error.vue'),
            beforeEnter: (to, from, next) => {
                updateUI.updateActivePage(to.name);
                next();
            },
        },
        {
            path: '/nosetup',
            name: 'nosetup',
            component: () => import('./views/nosetup.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/health-check-error',
            name: 'health-check-error',
            component: () => import('./views/HealthCheckError.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/non-lcp-error',
            name: 'non-lcp-error',
            component: () => import('./views/NonLcpUserError.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '*',
            component: () => import('./views/Clients.vue'),
            beforeEnter: (to, from, next) => {
                const storedRoute = lsm.getDirectRoute();

                if (storedRoute != null) {
                    next(storedRoute);
                } else if (client.clients.length === 1) {
                    next({ name: 'dashboard' });
                } else {
                    updateUI.updateActivePage(to.name);
                    next();
                }
            },
            meta: {
                requiresAuth: true,
                requiresClientData: true,
            },
        },
    ],
});

router.beforeResolve(async (to, from, next) => {
    const requestedQuery = typeof to.query === 'string' ? to.query : ({ ...to.query });

    await userLoggedIn().then(async (result) => {
        if (!result) {
            // not logged in
            let authInitString = null;

            if (typeof requestedQuery === 'string') {
                authInitString = requestedQuery;
            } else if (requestedQuery != null) {
                authInitString = requestedQuery.signIn;
                lsm.storeDirectRoute(to.path);
            }

            // auto sign in
            if (authInitString != null) {
                Auth.init();
                Auth.signIn();
                return;
            }

            if (to.matched.some((record) => record.meta.requiresAuth)) {
                next('/login');
            } else {
                next();
            }
        } else if (to.name === 'login') {
            next('/clients');
        } else if (to.name === 'clients') {
            // get latest client data each time
            updateUI.setLoader(true);
            const returnVal = await axios.get(`${process.env.VUE_APP_API}/api/GetUserSchemesV3`);

            const clients: Client[] = [];
            returnVal.data.forEach((c) => {
                clients.push(new Client(
                    c.id,
                    c.name,
                    c.hasPendingPortfolioAccess,
                    c.hasLivePortfolioAccess,
                ));
            });
            updateClient.updateClients(clients);
            updateUI.setLoader(false);
            next();
        } else if (to.matched.some((record) => record.meta.requiresClientData)) {
            if (client.clients.length === 0) {
                // need to populate
                updateUI.setLoader(true);
                const returnVal = await axios.get(`${process.env.VUE_APP_API}/api/GetUserSchemesV3`);

                const clients: Client[] = [];
                returnVal.data.forEach((c) => {
                    clients.push(new Client(
                        c.id,
                        c.name,
                        c.hasPendingPortfolioAccess,
                        c.hasLivePortfolioAccess,
                    ));
                });
                updateClient.updateClients(clients);
                updateUI.setLoader(false);
            }

            next();

            if (client.info === null && to.name !== 'clients' && !(to.name === 'dashboardwithidandview' || to.name === 'dashboardwithid')) {
                next('/clients');
            } else {
                next();
            }
        } else {
            next();
        }
    });
});

export default router;
