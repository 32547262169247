import { numberFormat } from 'highcharts';

const ValueShow = (val: number, scale: number):number => val / scale;

const Suffix = (scale: number): string => {
    if (scale < 2) return '';
    if (scale < 1001) return 'k';
    if (scale < 1000001) return 'm';
    if (scale < 1000000001) return 'bn';
    return 'tr';
};

const ValueFormat = (val: number, dec: number) => numberFormat(val, dec, '.', ',');

// eslint-disable-next-line import/prefer-default-export
export const Util = {
    valueFormat (value: number, scaling: number, decimals: number, prefix: string):string {
        if (value < 0) return `-${prefix}${ValueFormat(ValueShow(Math.abs(value), scaling), decimals)}${Suffix(scaling)}`;
        return `${prefix}${ValueFormat(ValueShow(value, scaling), decimals)}${Suffix(scaling)}`;
    },
    findDecimals (maxVal: number, scaling: number):number {
        if (maxVal === 0) return 2;

        let div = maxVal / scaling / 1000;

        let n = -3;

        while (div < 10) {
            div *= 10;
            n += 1;
        }
        return Math.max(n, 0);
    },
    DATE_FORMAT: 'DD/MM/YYYY',
    DATE_BACKEND: 'YYYY-MM-DD[T]HH:mm:ss',
    DATE_ISO: 'YYYY-MM-DD',
};
