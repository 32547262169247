import Vue from 'vue';
import ManagerESGData from '@/components/layout/esg/ManagerESGData';
// eslint-disable-next-line import/no-cycle
import { data } from './Data';

export const ESGData = Vue.observable({
    dataArray: [],
});

const cloneDeep = require('clone-deep');

export const manageESGData = {
    initialise () {
        const leafData = cloneDeep(data.leafData);
        ESGData.dataArray = [];
        if (leafData) {
            leafData.forEach((leaf) => {
                ESGData.dataArray.push(new ManagerESGData(
                    leaf.name,
                ));
            });
        }
    },
};
