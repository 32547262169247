import hello, {
    HelloJSTokenResponseType,
    HelloJSServiceDef,
    HelloJSLoginOptions,
} from 'hellojs';

const policies: any = {
    signInPolicy: `${process.env.VUE_APP_B2C_POLICY}`,
};

const responceType: HelloJSTokenResponseType = 'id_token token';

const signIn: string = 'signIn';

const signInDefinition: HelloJSServiceDef = {
    name: 'Azure B2C Sign Up',
    oauth: {
        version: 2,
        auth: `https://${process.env.VUE_APP_B2C_TENANTNAME}.b2clogin.com/${process.env.VUE_APP_B2C_TENANTNAME}.onmicrosoft.com/oauth2/v2.0/authorize?p=${policies.signInPolicy}`,
    },
    refresh: false,
    scope_delim: ' ',
    logout () {
    // get id_token from auth response
        const idToken = hello(signIn).getAuthResponse().id_token;
        // clearing local storage session
        hello.utils.store(signIn, null);
        // redirecting to Azure B2C logout URI
        window.location.href = `https://${process.env.VUE_APP_B2C_TENANTNAME}.b2clogin.com/${process.env.VUE_APP_B2C_TENANTNAME}.onmicrosoft.com/oauth2/v2.0/logout?p=${policies.signInPolicy}&id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.VUE_APP_B2C_LOGOUT_URI}`;
    },
    // Don't even try submitting via form.
    // This means no POST operations in <=IE9
    form: false,
};

const helloJsOptions: HelloJSLoginOptions = {
    display: 'page',
    scope: `openid ${process.env.VUE_APP_B2C_SCOPE}`,
    redirect_uri: '/',
    response_type: responceType,
};

export {
    signInDefinition,
    helloJsOptions,
    signIn,
};
