import { updateUI } from '@/observables/UI';

export default class SessionTimer {
    lastupdate: Date;

    timeoutHandler: number;

    timeoutInterval: number;

    inactivityCheck: number;

    lowWarning: boolean;

    constructor (inactivityCheckMinutes: number, timeoutCheckIntervalMinutes: number) {
        this.lastupdate = new Date();
        this.lowWarning = false;
        this.inactivityCheck = inactivityCheckMinutes * 60 * 1000;
        this.timeoutInterval = timeoutCheckIntervalMinutes * 60 * 1000;

        this.timeoutHandler = setInterval(() => {
            const curTime: Date = new Date();
            if (curTime.getTime() - this.lastupdate.getTime() > this.inactivityCheck) {
                this.lowWarning = true;
                updateUI.updateInactivity(true);
            } else {
                this.lowWarning = false;
                updateUI.updateInactivity(false);
            }
        }, this.timeoutInterval);
    }

    updateLastTouch ():void {
        this.lastupdate = new Date();
    }
}
