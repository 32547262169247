import dayjs from 'dayjs';

const directRouteKey = 'directRoute';

export default {
    storeValue (storageKey, value) {
        localStorage.setItem(storageKey, value);
    },
    getStoredValue (storageKey) {
        return localStorage.getItem(storageKey);
    },
    storeDirectRoute (routeToStore) {
        const objectToStore = {
            route: routeToStore,
            requestTime: dayjs(new Date()),
        };

        const objectAsString = JSON.stringify(objectToStore);
        this.storeValue(directRouteKey, objectAsString);
    },
    getDirectRoute () {
        const storedValue = this.getStoredValue(directRouteKey);
        if (storedValue == null) return null;

        const storedObject = JSON.parse(storedValue);

        if (dayjs(storedObject.requestTime).add(10, 'minute') > dayjs(new Date())) {
            const returnRoute = storedObject.route;
            this.clearDirectRoute();
            return returnRoute;
        }

        this.clearDirectRoute();
        return null;
    },
    clearDirectRoute () {
        localStorage.removeItem(directRouteKey);
    },
};
