import dayjs from 'dayjs';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

let helloObject: any;
let email: string;

function getHelloLocalStorageObject (commit: any): any {
    // look to see if we have hello object in local storage
    const helloJsonObject = localStorage.getItem('hello');
    if (helloJsonObject && (helloJsonObject === '{}' || helloJsonObject.includes('The+user+has+cancelled'))) {
        localStorage.removeItem('hello');
        commit('setAuthState', null);
        return null;
    }

    return helloJsonObject;
}

function parseHelloJsonSetLoginTypeAndReturnObject (
    jsonObject: string,
): boolean {
    const helloObj = JSON.parse(jsonObject);
    if (helloObj.lcpSignIn !== undefined) return false;
    if (helloObj.localSignIn !== undefined) return false;
    if (helloObj.signIn) {
        helloObject = helloObj.signIn;
        const decoded = jwtDecode(helloObj.signIn.access_token) as any;
        email = decoded.emails[0];
    }
    return true;
}

export default {
    namespaced: true,
    state: {
        authObject: null,
        access: null,
    },
    getters: {
        getAuthState: (s: { authObject: object }) => s.authObject,
        getAccess: (s: { access: String }) => s.access,
        getEmail: () => email,
        isLcp: () => email.includes('@lcp.uk.com'),
    },
    mutations: {
        setAuthState (state: any, auth: { [key: string]: any[] }) {
            state.authObject = auth;
        },
        setAccess (state: any, access: String) {
            state.access = access;
        },
    },
    actions: {
        async checkIfLoggedIn ({ commit, dispatch, state }: any) {
            const helloJsonObject = getHelloLocalStorageObject(commit);

            if (!helloJsonObject) {
                return false;
            }

            if (helloObject === undefined) {
                const res = parseHelloJsonSetLoginTypeAndReturnObject(
                    helloJsonObject,
                );
                if (!res) {
                    localStorage.removeItem('hello');
                    commit('setAuthState', null);
                    return false;
                }
            }
            // we have a local object, check if client login if not check if lcp login
            const authResponse: any = helloObject;

            if (authResponse) {
                // check to see if the session is still valid
                const expires = dayjs.unix(authResponse.expires);
                const now = dayjs(new Date());
                if (now.isBefore(expires)) {
                    commit('setAuthState', authResponse);
                    axios.defaults.headers.common.Authorization = `Bearer ${authResponse.access_token}`;

                    if (state.access === null) {
                        // await axios.get(`${process.env.VUE_APP_API}/api/UpdateUser`).then((access) => {
                        //     commit('setAccess', access.data);
                        // });
                    }
                    // set default headers
                } else {
                    // clear localstorage, redirect
                    localStorage.removeItem('hello');
                    commit('setAuthState', null);
                    return false;
                }
            }

            return true;
        },
    },
};
