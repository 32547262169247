export default class GovernanceMetrics {
    CombinedCEOChair: number;

    InsufficientBoardDiveristy: number;

    InsufficientIndependence: number;

    NoAntiBriberyPolicy: number;

    constructor (governanceScore: number) {
        // eslint-disable-next-line no-mixed-operators
        this.CombinedCEOChair = Math.round((Math.random() * 50 + 10) * 100 / governanceScore) / 100;

        // eslint-disable-next-line no-mixed-operators
        this.InsufficientBoardDiveristy = Math.round((Math.random() * 50 + 20) * 100 / governanceScore) / 100;

        // eslint-disable-next-line no-mixed-operators
        this.InsufficientIndependence = Math.round((Math.random() * 10 + 10) * 100 / governanceScore) / 100;

        // eslint-disable-next-line no-mixed-operators
        this.NoAntiBriberyPolicy = Math.round((Math.random() * 20 + 10) * 100 / governanceScore) / 100;
    }
}
