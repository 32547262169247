
import { Vue, Component } from 'vue-property-decorator';
import { client } from '@/observables/Client';
import AppPrivacyPolicy from '@/components/AppPrivacyPolicy.vue';

@Component({
    components: {
        AppPrivacyPolicy,
    },
})
export default class BottomBar extends Vue {
    get year (): string {
        const d = new Date();
        return d.getFullYear().toString();
    }

    get loggedIn (): boolean {
        return this.$store.state.auth.authObject !== null;
    }

    get clientSelected () {
        return client.info !== null && client.details !== null;
    }

    get clientName () {
        return client.info ? client.info.clientName : '';
    }

    get dateSource (): string {
        return client.details.dataSource;
    }
}
