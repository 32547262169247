import { ICurrencyDetailsDto } from '@/interfaces/dto/ICurrencyDetailsDto';
import { IPortfolioDto } from '@/interfaces/dto/IPortfolioDto';

export default class BaseData {
    currency: ICurrencyDetailsDto;

    interval: string;

    startDate: Date;

    endDate: Date;

    portfolioData: IPortfolioDto;

    constructor (_currency: ICurrencyDetailsDto, _interval: string, _startDate: Date, _endDate: Date, _portfolioData: IPortfolioDto) {
        this.currency = _currency;
        this.interval = _interval;
        this.startDate = _startDate;
        this.endDate = _endDate;
        this.portfolioData = _portfolioData;
    }
}
