
import { Vue, Component } from 'vue-property-decorator';
import AppPrivacyPolicy from '@/components/AppPrivacyPolicy.vue';

@Component({
    components: {
        AppPrivacyPolicy,
    },
})
export default class TermsWording extends Vue {
}
