
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import vueSlider from 'vue-slider-component';
import moment from 'moment';
import { Util } from '@/common/Util';

@Component({
    components: {
        vueSlider,
    },
})
export default class DateRangeSlider extends Vue {
  @Prop({ type: String, required: true }) readonly minStartDate!: string;

  @Prop({ type: String, required: true }) readonly maxEndDate!: string;

  @Prop(Array) readonly rangeOverride!: Array<string>;

  get allowedDateFormats (): string[] {
      const dayKeys = ['D', 'DD'];
      const monthKeys = ['M', 'MM'];
      const yearKeys = ['YY', 'YYYY'];
      const keyDelimiter = ['/', '.'];
      // loop through all allowed date formats and return result
      const result = [];
      keyDelimiter.forEach((delimiter) => {
          dayKeys.forEach((dayKey) => {
              monthKeys.forEach((monthKey) => {
                  yearKeys.forEach((yearKey) => {
                      result.push(`${dayKey}${delimiter}${monthKey}${delimiter}${yearKey}`);
                  });
              });
          });
      });
      return result;
  }

  get startDateDisplay () {
      const momentDate = moment(this.range[0], Util.DATE_ISO, true);
      if (!momentDate.isValid()) return 'Invalid Date';
      return moment(this.range[0]).format(Util.DATE_FORMAT);
  }

  get endDateDisplay () {
      const momentDate = moment(this.range[1], Util.DATE_ISO, true);
      if (!momentDate.isValid()) return 'Invalid Date';
      return moment(this.range[1]).format(Util.DATE_FORMAT);
  }

  isRange0Valid ():boolean {
      const date = this.range[0].trim();
      if (!date) return false;

      const momentDate = moment(date, Util.DATE_ISO);
      if (!momentDate.isValid()) return false;

      const momentEndDate = moment(this.range[1].trim(), Util.DATE_ISO);
      const momentMinStartDate = moment(this.minStartDate, Util.DATE_ISO);
      const momentMaxEndDate = moment(this.maxEndDate, Util.DATE_ISO);
      if (momentDate.isBefore(momentMinStartDate) || momentDate.isSameOrAfter(momentMaxEndDate) || momentDate.isSameOrAfter(momentEndDate)) return false;

      return true;
  }

  isRange1Valid ():boolean {
      const date = this.range[1].trim();
      if (!date) return false;

      const momentDate = moment(date, Util.DATE_ISO);
      if (!momentDate.isValid()) return false;

      const momentStartDate = moment(this.range[0].trim(), Util.DATE_ISO);
      const momentMinStartDate = moment(this.minStartDate, Util.DATE_ISO);
      const momentMaxEndDate = moment(this.maxEndDate, Util.DATE_ISO);
      if (momentDate.isSameOrBefore(momentMinStartDate) || momentDate.isAfter(momentMaxEndDate) || momentDate.isSameOrBefore(momentStartDate)) return false;

      return true;
  }

  handleInputChange (event: Event, indexToUpdate: 0 | 1) {
      const date = (event.target as HTMLInputElement).value;

      const momentDate = moment(date, this.allowedDateFormats, true);

      if (!momentDate.isValid()) {
          Vue.set(this.range, indexToUpdate, 'Invalid Date');
          return;
      }

      Vue.set(this.range, indexToUpdate, momentDate.format(Util.DATE_ISO));
  }

  public range: Array<string> = [];

  public dateRangeArray: Array<string> = [];

  created () {
      this.range[0] = this.rangeOverride[0];
      this.range[1] = this.rangeOverride[1];
      this.populateDateRange();
  }

  populateDateRange ():void {
      let currentDate = moment(this.minStartDate, Util.DATE_ISO, true);
      const stopDate = moment(this.maxEndDate, Util.DATE_ISO, true);
      while (currentDate.isSameOrBefore(stopDate)) {
          this.dateRangeArray.push(moment(currentDate).format(Util.DATE_ISO));
          currentDate = moment(currentDate).add(1, 'days');
      }
  }

  @Watch('rangeOverride')
  overrideRange (newRange: Array<string>) {
      Vue.set(this, 'range', newRange);
  }

  updateRange () {
      if (this.isRange0Valid() && this.isRange1Valid()) {
          this.$emit('update-range', this.range);
      }
  }
}
