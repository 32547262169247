
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import { data, updateData } from '@/observables/Data';
import { updateUI } from '@/observables/UI';
import dateRangeSlider from '@/components/inputs/dateRangeSlider.vue';
import { manageESGData } from '@/observables/ManagerESG';
import { Util } from '@/common/Util';
import { IPerformanceCalculationPeriod } from '@/interfaces/period/IPerformanceCalculationPeriod';

@Component({
    components: {
        dateRangeSlider,
    },
})

export default class DatePicker extends Vue {
    public sliderDateRange: [string, string] = [moment(data.startDate).format(Util.DATE_ISO), moment(data.endDate).format(Util.DATE_ISO)];

    timePeriods: Array<IPerformanceCalculationPeriod> = [];

    data = data;

    created () {
        // create a clone of the timePeriods array to avoid mutating the original array
        this.timePeriods = JSON.parse(JSON.stringify(data.timePeriods));

        // ensure the full range is set to the min and max dates
        const fullRange = this.timePeriods.find((tp: IPerformanceCalculationPeriod) => tp.key === 'Requested calculation period');

        if (fullRange) {
            fullRange.period.start = moment(this.data.minStartDate).format(Util.DATE_BACKEND);
            fullRange.period.end = moment(this.data.maxEndDate).format(Util.DATE_BACKEND);
        }
    }

    setDateRange (obj: IPerformanceCalculationPeriod): void {
        this.sliderDateRange = [moment(obj.period.start, Util.DATE_BACKEND).format(Util.DATE_ISO), moment(obj.period.end, Util.DATE_BACKEND).format(Util.DATE_ISO)];
        this.timePeriods.forEach((tp: IPerformanceCalculationPeriod) => {
            tp.isSelected = tp.key === obj.key;
        });

        updateData.updateTimePeriods(this.timePeriods);

        updateUI.SetDatePicker(false);
        this.getNewData();
    }

    getDateRange (period: IPerformanceCalculationPeriod): string {
        return `${moment(period.period.start).format(Util.DATE_FORMAT)} - ${moment(period.period.end).format(Util.DATE_FORMAT)}`;
    }

    // check if the time period is selected and is the current date range
    isActive (obj: IPerformanceCalculationPeriod): boolean {
        const sliderDateRangeString = this.sliderDateRange.join('');
        // get the obj date, stripping out from the T
        const objDateRangeString = obj.period.start.split('T')[0] + obj.period.end.split('T')[0];
        return sliderDateRangeString === objDateRangeString;
    }

    updateRangeSlider (rangeArray: [string, string]): void {
        this.sliderDateRange = [...rangeArray];
        updateUI.SetDatePicker(false);
        this.getNewData();
    }

    async getNewData () {
        updateUI.setLoader(true);

        // if dates are entered where 'from' is actually after 'to'
        if (moment(this.sliderDateRange[0], Util.DATE_ISO).isAfter(moment(this.sliderDateRange[1], Util.DATE_ISO))) {
            updateData.updateStartEndDate(moment(this.sliderDateRange[1], Util.DATE_ISO).toDate(), moment(this.sliderDateRange[0], Util.DATE_ISO).toDate(), true);
        } else {
            updateData.updateStartEndDate(moment(this.sliderDateRange[0], Util.DATE_ISO).toDate(), moment(this.sliderDateRange[1], Util.DATE_ISO).toDate(), true);
        }

        await updateData.getDataUpdate();
        updateUI.SetDatePicker(false);
        updateUI.setLoader(false);
        manageESGData.initialise();
    }

    get minStartDate (): String {
        return moment(data.minStartDate.toISOString()).format(Util.DATE_ISO);
    }

    get maxEndDate (): String {
        return moment(data.maxEndDate.toISOString()).format(Util.DATE_ISO);
    }
}
