import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import cloneDeep from 'clone-deep';
import BaseData from '@/assets/models/BaseData';
import { Util } from '@/common/Util';
import router from '@/router';
import NodePropertyOption from '@/enums/NodePropertyOption';
import ApiHandler from '@/api/apiHandler';
import { IGetDataStreamsRequestDto } from '@/interfaces/dto/IGetDataStreamsRequestDto';
import { IPortfolioDto } from '@/interfaces/dto/IPortfolioDto';
import { IPerformanceCalculationPeriod } from '@/interfaces/period/IPerformanceCalculationPeriod';
import { ICurrencyDetailsDto } from '@/interfaces/dto/ICurrencyDetailsDto';
import Client, { client, updateClient } from './Client';
import { updateUI } from './UI';

interface Store {
    base: BaseData,
    investmentSelected: Record<number, boolean>,
    twrrSelected: Record<number, boolean>,
    benchmarkSelected: Record<number, boolean>,
    secondaryBenchmarksSelected: Record<number, boolean>,
    mwrrSelected: Record<number, boolean>,
    currency: ICurrencyDetailsDto,
    dataLevel: Array<number>,
    endDate: Date,
    endDateRequest: Date,
    leafData: Array<IPortfolioDto>,
    leafDataPartPeriod: Array<IPortfolioDto>,
    levelData: IPortfolioDto,
    levelDataDecimals: number,
    levelDataScale: number,
    loaded: boolean,
    maxEndDate: Date,
    minStartDate: Date,
    startDate: Date,
    startDateRequest: Date,
    timePeriods: Array<IPerformanceCalculationPeriod>,
}

export const data: Store = Vue.observable({
    base: null,
    investmentSelected: {},
    twrrSelected: {},
    benchmarkSelected: {},
    secondaryBenchmarksSelected: {},
    mwrrSelected: {},
    currency: null,
    dataLevel: [0],
    endDate: null,
    endDateRequest: null,
    leafData: null,
    leafDataPartPeriod: null,
    levelData: null,
    levelDataDecimals: 0,
    levelDataScale: 1_000_000,
    loaded: false,
    maxEndDate: null,
    minStartDate: null,
    startDate: null,
    startDateRequest: null,
    timePeriods: [],
    hierarchy: null,
});

export const dataGetter = {
    dataYears (): number {
        if (data.endDate === null) return 0;
        const val: number = moment(data.endDate).diff(moment(data.startDate), 'years', true);
        return val;
    },
    endPortfolioValue (): number | null | undefined {
        if (data.base === null) return null;
        const valData = data.base.portfolioData.investmentData.marketValueResults?.data.data;
        return valData[valData.length - 1];
    },
    levelData () {
        if (data.base === null) return null;
        const dataAtLevel = cloneDeep(data.base.portfolioData);
        const dataLevelArray = [...data.dataLevel];
        dataLevelArray.shift();
        let output = dataAtLevel;

        dataLevelArray.forEach((i) => {
            if (output.children.length && output.children[i]) {
                output = output.children[i];
            } else {
                output = dataAtLevel;
            }
        });

        const maxVal = Math.max(...output.investmentData.marketValueResults.data.data);

        data.levelDataDecimals = Util.findDecimals(maxVal, data.levelDataScale);
        return output;
    },
    leafPartPeriod (item: IPortfolioDto): boolean {
        const start = moment(item.investmentData.totalReturnSeriesResults?.totalReturnSeries?.startDate);
        const end = moment(item.investmentData.totalReturnSeriesResults?.totalReturnSeries?.endDate);
        const val = end.diff(moment(data.endDate), 'years', true) + start.diff(moment(data.startDate), 'years', true);
        return Math.abs(val) > 0;
    },
    leafData (item: IPortfolioDto, partPeriod: boolean): Array<IPortfolioDto> | null {
        if (data.base === null) return null;
        const collection: IPortfolioDto[] = [];
        const statistics = item.investmentData.benchmarkResults?.primaryBenchmarkResult?.totalReturnSeriesResults?.statistics ?? null;
        if (item.isLeafNode && statistics && partPeriod === dataGetter.leafPartPeriod(item)) {
            collection.push(cloneDeep(item));

            return collection;
        }
        item.children.forEach((child) => {
            const items: IPortfolioDto[] = dataGetter.leafData(child, partPeriod);
            items.forEach((val) => {
                collection.push(val);
            });
        });

        return collection;
    },
};

export const updateData = {
    updateDataLevel (dataLevel: Array<number>): void {
        data.dataLevel = dataLevel;
        data.levelData = dataGetter.levelData();
    },
    updateTimePeriods (timePeriods: Array<IPerformanceCalculationPeriod>): void {
        data.timePeriods = timePeriods;
    },
    updateTimePeriodIsSelected (timePeriod: IPerformanceCalculationPeriod, isSelected: boolean): void {
        data.timePeriods.find((x) => x.key === timePeriod.key).isSelected = isSelected;
    },

    selectInvestment (id:number): void {
        Vue.set(data.investmentSelected, id, true);
    },
    deselectInvestment (id:number): void {
        Vue.set(data.investmentSelected, id, false);
    },
    selectBenchmark (id:number): void {
        Vue.set(data.benchmarkSelected, id, true);
    },
    deselectBenchmark (id:number): void {
        Vue.set(data.benchmarkSelected, id, false);
    },
    selectSecondaryBenchmark (id:number): void {
        Vue.set(data.secondaryBenchmarksSelected, id, true);
    },
    deselectSecondaryBenchmark (id:number): void {
        Vue.set(data.secondaryBenchmarksSelected, id, false);
    },
    selectTWRR (id:number) {
        Vue.set(data.twrrSelected, id, true);
    },
    deselectTWRR (id:number) {
        Vue.set(data.twrrSelected, id, false);
    },
    selectMWRR (id:number) {
        Vue.set(data.mwrrSelected, id, true);
    },
    deselectMWRR (id:number) {
        Vue.set(data.mwrrSelected, id, false);
    },
    toggleSelectedNode (node: IPortfolioDto, nodeId: number, propertySelection: NodePropertyOption, setTo?: boolean, isAll: boolean = false): void {
        let nodePropertyName = '';

        switch (propertySelection) {
        case NodePropertyOption.Investment:
            nodePropertyName = 'investmentSelected';
            break;
        case NodePropertyOption.Twrr:
            nodePropertyName = 'twrrSelected';
            break;
        case NodePropertyOption.Mwrr:
            nodePropertyName = 'mwrrSelected';
            break;
        case NodePropertyOption.Benchmark:
            nodePropertyName = 'benchmarkSelected';
            break;
        case NodePropertyOption.SecondaryBenchmarks:
            nodePropertyName = 'secondaryBenchmarksSelected';
            break;
        default: break;
        }

        // Determine if we should toggle this node
        if (node.childEntityNameId === nodeId || isAll) {
            if (data[nodePropertyName][node.childEntityNameId] === undefined) {
                Vue.set(data[nodePropertyName], node.childEntityNameId, true);
            }

            if (isAll) {
                Vue.set(data[nodePropertyName], node.childEntityNameId, setTo);
            } else {
                Vue.set(data[nodePropertyName], node.childEntityNameId, !data[nodePropertyName][node.childEntityNameId]);
                return;
            }
        }

        // Recurse for child nodes
        node.children.forEach((child) => {
            this.toggleSelectedNode(child, nodeId, propertySelection, setTo, isAll);
        });
    },
    deselectAllInvestments (propertyOption: NodePropertyOption | null = null): void {
        if (propertyOption === null) {
            Object.values(NodePropertyOption).filter((v) => typeof v === 'number').forEach((option) => {
                this.toggleSelectedNode(data.base.portfolioData, -1, option, false, true);
            });
        } else {
            this.toggleSelectedNode(data.base.portfolioData, -1, propertyOption, false, true);
        }
    },
    selectAllInvestments (propertyOption: NodePropertyOption | null = null): void {
        if (propertyOption === null) {
            Object.values(NodePropertyOption).filter((v) => typeof v === 'number').forEach((option) => {
                this.toggleSelectedNode(data.base.portfolioData, -1, option, true, true);
            });
        } else {
            this.toggleSelectedNode(data.base.portfolioData, -1, propertyOption, true, true);
        }
    },
    updateNodeColour (node: IPortfolioDto, nodeId: number, colour: string): void {
        if (node.childEntityNameId === nodeId) {
            Vue.set(node, 'colour', colour);
            return;
        }

        node.children.forEach((child) => {
            this.updateNodeColour(child, nodeId, colour);
        });
    },
    async initialiseClient (clientRequested: Client) {
        try {
            const isLivePortfolio = client.selectedTypePortfolio.type === 'live';
            const clientDetails = await ApiHandler.getClientDetails(clientRequested.clientId, isLivePortfolio);
            updateClient.updateClientDetails(clientDetails);

            const requestObj: IGetDataStreamsRequestDto = {
                clientId: clientRequested.clientId,
                currency: clientDetails.defaultCurrency.currencyCode,
                startDate: null,
                endDate: null,
                livePortfolio: isLivePortfolio,
            };

            const response = await ApiHandler.getDataStreams(requestObj);

            if (!response) throw new Error('No response from server');

            const returnVal = response;
            const portfolioChildren = returnVal.portfolio.children[0];

            if (!portfolioChildren) throw new Error('No portfolio children found');

            const returnDataObj = new BaseData(
                clientDetails.defaultCurrency,
                'Daily',
                moment(portfolioChildren.investmentData.marketValueResults?.data.startDate).toDate(),
                moment(portfolioChildren.investmentData.marketValueResults?.data.endDate).toDate(),
                portfolioChildren,
            );

            updateData.setBaseData(returnDataObj);
            const validTimePeriods = returnVal.performanceCalculationPeriods.filter((timePeriod) => {
                const periodStart = moment(timePeriod.period.start, Util.DATE_BACKEND).toDate();
                const periodEnd = moment(timePeriod.period.end, Util.DATE_BACKEND).toDate();
                return periodStart >= data.minStartDate && periodEnd <= data.maxEndDate;
            });
            // create new array with isSelected property for each time period
            // for the key "Requested calculation period" make sure it is selected
            const validTimePeriodsSelected: IPerformanceCalculationPeriod[] = validTimePeriods.map((timePeriod) => ({ ...timePeriod, isSelected: timePeriod.key === 'Requested calculation period', isDisabled: false }));

            updateData.updateTimePeriods(validTimePeriodsSelected);

            // Select the client's root investment
            // TODO: currently need to set all values to track selected or not
            this.selectAllInvestments();
            this.deselectAllInvestments();
            this.selectInvestment(data.base.portfolioData.childEntityNameId);
        } catch (error) {
            const userEmail: string = (await axios.get(`${process.env.VUE_APP_API}/api/GetUserEmail`)).data;

            if (userEmail.endsWith('@lcp.uk.com')) {
                localStorage.setItem('clientName', clientRequested.clientName);
                // ? why is error.response undefined?
                localStorage.setItem('errorText', error.response?.statusText ?? 'There was an error loading the data for this client.');
                router.push('/health-check-error');
                updateUI.setLoader(false);
                return;
            }
            router.push('/non-lcp-error');
        }
    },
    async getDataUpdate () {
        const requestObj: IGetDataStreamsRequestDto = {
            clientId: client.info.clientId,
            currency: data.currency.currencyCode,
            startDate: moment(data.startDateRequest).format('DD/MM/YYYY'),
            endDate: moment(data.endDateRequest).format('DD/MM/YYYY'),
            livePortfolio: client.selectedTypePortfolio.type === 'live',
        };
        try {
            const response = await ApiHandler.getDataStreams(requestObj);

            if (!response) throw new Error('No response from server');

            const returnVal = response;
            const portfolioChildren = returnVal.portfolio.children[0];

            const returnDataObj = new BaseData(
                data.currency,
                'Daily',
                moment(portfolioChildren.investmentData.marketValueResults?.data.startDate).toDate(),
                moment(portfolioChildren.investmentData.marketValueResults?.data.endDate).toDate(),
                portfolioChildren,
            );

            updateData.updateBaseData(returnDataObj);

            const validTimePeriods = returnVal.performanceCalculationPeriods.filter((timePeriod) => {
                const periodStart = moment(timePeriod.period.start, Util.DATE_BACKEND).toDate();
                const periodEnd = moment(timePeriod.period.end, Util.DATE_BACKEND).toDate();
                return periodStart >= data.minStartDate && periodEnd <= data.maxEndDate;
            });

            // default to isSelected = true for the "Requested calculation period"
            const validTimePeriodsSelected: IPerformanceCalculationPeriod[] = validTimePeriods.map((timePeriod) => ({ ...timePeriod, isSelected: timePeriod.key === 'Requested calculation period', isDisabled: false }));

            // check if the time period is also the requested calculation period, if so, set isSelected to true for the time period and false for the requested calculation period
            validTimePeriodsSelected.forEach((timePeriod) => {
                if (timePeriod.key !== 'Requested calculation period') {
                    if (timePeriod.period.start === moment(data.startDateRequest).format(Util.DATE_BACKEND) && timePeriod.period.end === moment(data.endDateRequest).format(Util.DATE_BACKEND)) {
                        timePeriod.isSelected = true;
                        validTimePeriodsSelected.find((x) => x.key === 'Requested calculation period').isSelected = false;
                    }
                }
            });

            updateData.updateTimePeriods(validTimePeriodsSelected);

            updateData.updateVariablesFromRequest();
        } catch (error) {
            const userEmail: string = (await axios.get(`${process.env.VUE_APP_API}/api/GetUserEmail`)).data;

            if (userEmail.endsWith('@lcp.uk.com')) {
                localStorage.setItem('clientName', client.info.clientName);
                localStorage.setItem('errorText', error.response.statusText);
                router.push('/health-check-error');
                updateUI.setLoader(false);
                return;
            }

            router.push('/non-lcp-error');
        }
    },
    setBaseData (_data: BaseData) {
        data.base = _data;
        data.startDate = _data.startDate;
        data.endDate = _data.endDate;
        data.minStartDate = _data.startDate;
        data.maxEndDate = _data.endDate;
        data.levelData = dataGetter.levelData();
        data.leafData = dataGetter.leafData(cloneDeep(data.base.portfolioData), false);
        data.leafDataPartPeriod = dataGetter.leafData(cloneDeep(data.base.portfolioData), true);
        data.loaded = true;
        data.startDateRequest = new Date(data.startDate.getTime());
        data.endDateRequest = new Date(data.endDate.getTime());
        data.currency = _data.currency;
    },
    updateBaseData (_data: BaseData) {
        data.base = _data;
        data.startDate = _data.startDate;
        data.endDate = _data.endDate;
        data.levelData = dataGetter.levelData();
        data.leafData = dataGetter.leafData(cloneDeep(data.base.portfolioData), false);
        data.leafDataPartPeriod = dataGetter.leafData(cloneDeep(data.base.portfolioData), true);
    },
    updateStartEndDate (startDate: Date, endDate: Date, requestOnly: boolean) {
        if (requestOnly) {
            data.startDateRequest = startDate;
            data.endDateRequest = endDate;
        } else {
            data.startDate = startDate;
            data.endDate = endDate;
        }
    },
    updateCurrency (_currency: string) {
        const updatedCurrency = client.details.currencies.find((x) => x.currencyCode === _currency);
        data.currency = updatedCurrency;
    },
    updateVariablesFromRequest () {
        data.startDate = new Date(data.startDateRequest.getTime());
        data.endDate = new Date(data.endDateRequest.getTime());
    },
    setLoaded () {
        data.loaded = true;
    },
};
