import Vue from 'vue';

interface Store {
    currencyOptions: string[],
    loader: boolean,
    datePicker: boolean,
    inactivity: boolean,
    showTree: boolean,
    activePage: string,
    seenTerms: boolean,
    termslastUpdated: number,
    error: boolean,
    print: boolean,
    unauth: boolean,
}

export const ui: Store = Vue.observable({
    currencyOptions: ['British Pound'],
    loader: false,
    datePicker: false,
    inactivity: false,
    showTree: false,
    activePage: 'clients',
    seenTerms: false,
    termslastUpdated: 1696927798,
    error: false,
    print: false,
    unauth: false,
});

export const updateUI = {
    updateCurrencyOptions (currencies: string[]) {
        ui.currencyOptions = currencies;
    },
    setLoader (isLoading: boolean) {
        ui.loader = isLoading;
    },
    SetDatePicker (showDateRange: boolean) {
        ui.datePicker = showDateRange;
    },
    updateInactivity (inactive: boolean) {
        ui.inactivity = inactive;
    },
    updateShowTree (show: boolean) {
        ui.showTree = show;
    },
    updateActivePage (page: string) {
        ui.activePage = page;
    },
    updateSeenTerms () {
        ui.seenTerms = true;
    },
    updateError (error: boolean) {
        ui.error = error;
    },
    updatePrint (print: boolean) {
        ui.print = print;
        ui.loader = print;
    },
    setUnauth () {
        ui.unauth = true;
    },
};
