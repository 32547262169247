// eslint-disable-next-line no-shadow
enum NodePropertyOption {
    Investment,
    Twrr,
    Benchmark,
    SecondaryBenchmarks,
    Mwrr,
}

export default NodePropertyOption;
