import axios from 'axios';
import { IGetDataStreamsRequestDto } from '@/interfaces/dto/IGetDataStreamsRequestDto';
import { IGetDataStreamsDto } from '@/interfaces/dto/IGetDataStreamsDto';
import { IClientDetailsDto } from '@/interfaces/dto/IClientDetails';

export default class ApiHandler {
    static async getDataStreams (payload: IGetDataStreamsRequestDto): Promise<IGetDataStreamsDto> {
        return (await axios.post(`${process.env.VUE_APP_API}/api/GetDataStreamsV3`, payload)).data as IGetDataStreamsDto;
    }

    static async getClientDetails (clientId: string, isLivePortfolio: boolean): Promise<IClientDetailsDto> {
        return (await axios.get(`${process.env.VUE_APP_API}/api/GetClientDetails/${clientId}/${isLivePortfolio}`)).data as IClientDetailsDto;
    }
}
